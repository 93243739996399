import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import LogoImage from "images/bizmut.svg";

import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";

const Container = tw.div`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FiveColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;
const LogoImg = tw.img`w-8`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-primary-400 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;

export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <Column>
            <ColumnHeading>Başlıca</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#hero">Anasayfa</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#features">Felsefemiz</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#mainFeature">Hizmetlerimiz</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#values">Değerlerimiz</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#faq">SSS</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>İletişim Bilgileri</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <span>
                  Adres: ÜNİVERSİTELER MAH. 1597 CADDE KÜME EVLER NO: 3
                  ÇANKAYA/ANKARA
                </span>
              </LinkListItem>
              <LinkListItem>
                <span>Telefon: 0312 478 35 35</span>
              </LinkListItem>
              <LinkListItem>
                <span>E-posta: bizmutas@gmail.com</span>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            {/* <LogoImg src={LogoImage} /> */}
            <LogoText>Bizmut</LogoText>
          </LogoContainer>
          <CopywrightNotice>
            &copy; 2024 Bizmut Yazılım Teknoloji ve Ticaret Anonim Şirketi. Tüm
            Hakları Saklıdır.
          </CopywrightNotice>
          {/* <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
